import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DateParser {
  public static unparseDate(ngbDate: NgbDateStruct): string {
    return ngbDate.day + '-' + ngbDate.month + '-' + ngbDate.year;
  }

  public static parseDate(date: string): NgbDateStruct {
    return {
      day: +date.split('-')[0],
      month: +date.split('-')[1],
      year: +date.split('-')[2]
    };
  }
  //2018-04-02T23:00:00+0000
  public static parseDateJsonb(date: string): NgbDateStruct {
    return {
      day: +date.split('-')[2].substr(0, 2),
      month: +date.split('-')[1],
      year: +date.split('-')[0]
    };
  }

  public static unparseDateJsonb(ngbDate: NgbDateStruct): string {
    return (
      ngbDate.year + '-' + ngbDate.month.toString().padStart(2, '0') + '-' + ngbDate.day.toString().padStart(2, '0') + 'T00:00:00+0000'
    );
  }

  // Funcion que recibe una fecha en formato dd-mm-yyyy y devuelve el dia
  public static getDay(fecha: string): string {
    return fecha != null && fecha.length > 0 ? fecha.split('-')[0].padStart(2, '0') : null;
  }
  // Funcion que recibe una fecha en formato dd-mm-yyyy y devuelve el mes
  public static getMonth(fecha: string): string {
    return fecha != null && fecha.length > 0 ? fecha.split('-')[1].padStart(2, '0') : null;
  }
  // Funcion que recibe una fecha en formato dd-mm-yyyy y devuelve el año
  public static getYear(fecha: string): string {
    return fecha != null && fecha.length > 0 ? fecha.split('-')[2] : null;
  }

  // obtenemos la hora a partir de la cadena recibida con formato 'hh:mm:ss'
  public static getHour(horaMinutoSegundos: string): string {
    return horaMinutoSegundos != null && horaMinutoSegundos.length > 0 ? horaMinutoSegundos.split(':')[0].padStart(2, '0') : null;
  }

  // obtenemos los minutos a partir de la cadena recibida con formato 'hh:mm:ss'
  public static getMinute(horaMinutoSegundos: string): string {
    return horaMinutoSegundos != null && horaMinutoSegundos.length > 0 ? horaMinutoSegundos.split(':')[1].padStart(2, '0') : null;
  }

  // obtenemos los segundos a partir de la cadena recibida con formato 'hh:mm:ss'
  public static getSecond(horaMinutoSegundos: string): string {
    return horaMinutoSegundos != null && horaMinutoSegundos.length > 0 ? horaMinutoSegundos.split(':')[2].padStart(2, '0') : null;
  }

  public static obtenerFecha(fecha: string): string {
    return this.getYear(fecha) + '-' + this.getMonth(fecha) + '-' + this.getDay(fecha);
  }
  /* Funcion que recibe una fecha en formato dd-mm-yyyy (o con una d o una m) y devuelve un string con formato yyyy-MM-hhT00:00:00+0000
   Al la fecha le concatena a su derecha la hora, minuto y segundo asi 'T00:00:00+0000' */
  public static crearFechaConFormatoJsonb(fecha: string): string {
    return this.obtenerFecha(fecha) + 'T00:00:00' + '+0200';
  }

  // Funcion que recibe un Date con formato 'Thu Jun 20 2019 00:00:00 GMT+0200' y devuelve un string con formato yyyy-MM-hhT00:00:00+0000
  public static obtenerFechaConFormatoJsonb(fecha: Date): string {
    // Con toLocaleString() obtenemos el formato 'd/m/yyyy 0:00:00'
    let cadenaFecha = fecha.toLocaleString();
    // obtenemos solo la fecha 'd/m/yyyy'
    cadenaFecha = cadenaFecha.split(' ')[0];
    cadenaFecha = cadenaFecha?.replace('/', '-')?.replace('/', '-');
    return this.crearFechaConFormatoJsonb(cadenaFecha);
  }

  // Funcion que devuelve una fecha con formato JSON, por ejemplo, 2019-06-20T11:53:35+0000
  public static crearFechaYHoraMinutoSegundosConFormatoJsonb(fecha: string, horaMinutoSegundos: string): string {
    return (
      this.obtenerFecha(fecha) +
      'T' +
      this.getHour(horaMinutoSegundos) +
      ':' +
      this.getMinute(horaMinutoSegundos) +
      ':' +
      this.getSecond(horaMinutoSegundos) +
      '+0000'
    );
  }

  // Funcion que recibe un Date con formato 'Thu Jun 20 2019 11:52:25 GMT+0200' y devuelve un string con formato 2019-06-20T11:53:35+0000
  public static obtenerFechaYHoraMinutoSegundoConFormatoJsonb(fecha: Date): string {
    if (!fecha) return null;
    // Con toLocaleString() obtenemos el formato 'd/m/yyyy h:mm:ss'
    const cadenaFechaYHoraMinutoSegundos = fecha.toLocaleString();

    // obtenemos solo la fecha 'd/m/yyyy'
    let cadenaFecha = cadenaFechaYHoraMinutoSegundos.split(' ')[0];
    cadenaFecha = cadenaFecha?.replace('/', '-')?.replace('/', '-')?.replace(',', '');
    // obtenemos solo la fecha 'hh:mm:ss'
    const cadenaHoraMinutoSegundos = cadenaFechaYHoraMinutoSegundos.split(' ')[1];
    return this.crearFechaYHoraMinutoSegundosConFormatoJsonb(cadenaFecha, cadenaHoraMinutoSegundos);
  }

  // Funcion que recibe un Date con formato 'Thu Jun 20 2019 11:52:25 GMT+0200' y devuelve un string con formato 2019-06-20T11:53:35+0000
  public static obtenerFechaFormatoJsonb(fecha: Date): string {
    if (!fecha) return null;
    // Con toLocaleString() obtenemos el formato 'd/m/yyyy h:mm:ss'
    const cadenaFechaYHoraMinutoSegundos = fecha.toLocaleString();

    // obtenemos solo la fecha 'd/m/yyyy'
    let cadenaFecha = cadenaFechaYHoraMinutoSegundos.split(' ')[0];
    cadenaFecha = cadenaFecha?.replace('/', '-')?.replace('/', '-')?.replace(',', '');
    // obtenemos solo la fecha 'hh:mm:ss'

    return this.obtenerFecha(cadenaFecha);
  }
}

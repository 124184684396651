import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

@Injectable({
  providedIn: 'root'
})
export class FiestasNacionalesService {
  private fiestasNacionales: string;

  FINDESEMANA = [];

  constructor(@Inject(APP_CONFIG) private appConfig) {
    dayjs.extend(weekday);
    // Primera aproximación para la previsión de fecha de entrega. Los festivos se han recuperado de https://santjust.net/festesoficials
    this.FINDESEMANA = [dayjs().day(6).weekday(), dayjs().day(0).weekday()];
    this.fiestasNacionales = this.appConfig.fiestasNacionales;
  }

  // Cálcula el lunes y viernes santo
  private pascua($annio: number) {
    let $M = 0;
    let $N = 0;
    if ($annio > 1583 && $annio < 1699) {
      $M = 22;
      $N = 2;
    } else if ($annio > 1700 && $annio < 1799) {
      $M = 23;
      $N = 3;
    } else if ($annio > 1800 && $annio < 1899) {
      $M = 23;
      $N = 4;
    } else if ($annio > 1900 && $annio < 2099) {
      $M = 24;
      $N = 5;
    } else if ($annio > 2100 && $annio < 2199) {
      $M = 24;
      $N = 6;
    } else if ($annio > 2200 && $annio < 2299) {
      $M = 25;
      $N = 0;
    }
    const $a = $annio % 19;
    const $b = $annio % 4;
    const $c = $annio % 7;
    const $d = (19 * $a + $M) % 30;
    const $e = (2 * $b + 4 * $c + 6 * $d + $N) % 7;
    const $f = $d + $e;

    let $dia = 0;
    let $mes = 0;

    if ($f < 10) {
      $dia = $f + 22;
      $mes = 3;
    } else {
      $dia = $f - 9;
      $mes = 4;
    }
    if ($dia === 26 && $mes === 4) {
      $dia = 19;
    }
    if ($dia === 25 && $mes === 4 && $d === 28 && $e === 6 && $a > 10) {
      $dia = 18;
    }
    const $pascua = new Date($annio, $mes - 1, $dia);

    const lunesPascua = dayjs($pascua).add(1, 'days');
    const viernesSanto = dayjs($pascua).subtract(2, 'days');

    return [lunesPascua, viernesSanto];
  }

  public agregarLaborables(fecha: Date, diasAgregar): Date {
    const pascua = this.pascua(fecha.getFullYear());
    const fiestaNacionales = this.fiestasNacionales
      .concat(pascua[0].format('MM-DD'))
      .concat(pascua[1].format('MM-DD'))
      .concat(pascua[0].add(49, 'days').format('MM-DD'));

    let daysAdded = 0;
    let dayjsDate = dayjs(fecha);
    while (daysAdded < diasAgregar) {
      dayjsDate = dayjsDate.add(1, 'days');
      if (!this.FINDESEMANA.includes(dayjsDate.weekday()) && !fiestaNacionales.includes(dayjsDate.format('MM-DD'))) {
        daysAdded++;
      }
    }

    return dayjsDate.toDate();
  }
}
